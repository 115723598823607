<template>
  <!-- Button trigger modal -->
  <button
    type="button"
    class="btn btn-primary btn-sm"
    data-bs-toggle="modal"
    :data-bs-target="`#${id}`"
  >
    Move card
  </button>

  <!-- Modal -->
  <div
    :id="id"
    class="modal fade"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Move card
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
          />
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div class="row mb-3">
              <div class="col">
                <label
                  class="form-label"
                >
                  Target
                </label>
                <select
                  v-model="containerTarget"
                  class="form-control"
                >
                  <option
                    v-for="value in containers"
                    :key="value.id"
                    :value="value.newId"
                  >
                    {{ value.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label
                  class="form-label"
                >
                  Pile
                </label>
                <select
                  v-model="pileTarget"
                  class="form-control"
                  :disabled="containerTarget === null"
                >
                  <option
                    v-for="value in piles"
                    :key="value"
                    :value="value"
                  >
                    {{ value }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            :disabled="containerTarget === null || pileTarget === null"
            @click="transfer"
          >
            Move
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed, defineComponent, onMounted, PropType, ref,
} from 'vue';
import { getContainerType, getId } from '@/modules/core/utils';
import { store } from '@/modules/core/store';
import {
  Container,
  Deck, Hand, HandPileType, PileType,
} from '@/modules/deck/deck.types';
import { useDeck } from '@/modules/deck/deck.service';
import { Modal } from 'bootstrap';

export default defineComponent({
  name: 'MoveCard',
  props: {
    card: {
      required: true,
      type: Object,
    },
    container: {
      required: true,
      type: Object as PropType<Deck & Hand>,
    },
    pile: {
      required: true,
      type: String as PropType<PileType>,
    },
    index: {
      required: true,
      type: Number,
    },
  },
  setup(props) {
    const id = `modal-${getId()}`;
    let modal: Modal;
    const isDeck = computed(() => props.container.pileHand === undefined);
    const containerTarget = ref<string | null>(null);
    const pileTarget = ref<PileType | null>(null);
    const idToContainers: Record<string, Container> = {};

    onMounted(() => {
      // @ts-ignore
      modal = new bootstrap.Modal(document.getElementById(id));
    });

    const preprocessContainers = (containers: Array<Container>, remove = false) => containers.map((container) => {
      const containerNew = { ...container };
      const type = getContainerType(containerNew);
      // @ts-ignore
      containerNew.newId = `${containerNew.id}_${type}`;
      // @ts-ignore
      containerNew.type = type;
      // @ts-ignore
      idToContainers[containerNew.newId] = containerNew;
      return containerNew;
    }).filter((container) => remove === false || container.id !== props.container.id);

    return {
      id,
      isDeck,
      containerTarget,
      pileTarget,
      piles: computed<Array<HandPileType>>(() => {
        if (containerTarget.value === null) {
          return [];
        }
        const target = idToContainers[containerTarget.value];

        let result: Array<PileType> = [];
        if (target === undefined) {
          return result;
        }
        result = ['CARDS', 'DISPLAY', 'DISCARD'];
        return getContainerType(target) === 'DECK' ? result : result.concat(['HAND']);
      }),
      containers: computed(() => {
        let result: Array<Container> = [];

        if (isDeck.value) {
          // @ts-ignore
          result = result.concat(preprocessContainers(store.moduleDeck.state.value.decks, false));
          // @ts-ignore
          result = result.concat(preprocessContainers(store.moduleDeck.state.value.hands));
        } else {
          // @ts-ignore
          result = result.concat(preprocessContainers(store.moduleDeck.state.value.decks));
          // @ts-ignore
          result = result.concat(preprocessContainers(store.moduleDeck.state.value.hands, false));
        }

        return result;
      }),
      transfer: () => {
        if (containerTarget.value === null || pileTarget.value === null) {
          return;
        }
        useDeck.transfer(props.container, props.pile, idToContainers[containerTarget.value], pileTarget.value);
        modal.hide();
      },
    };
  },
});
</script>

<style scoped>

</style>
