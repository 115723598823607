<template>
  <nav class="navbar navbar-light bg-light">
    <div class="container">
      <span class="navbar-brand">Spiel</span>
      <select-hand />
      <start-session />
    </div>
  </nav>
  <div
    v-if="store.moduleDeck.state.value.activeHand"
    class="container pt-3"
  >
    <router-view />
  </div>
</template>

<script lang="ts">
import { ServiceData } from '@/modules/core/data.service';
import StartSession from '@/modules/session/start-session.vue';
import SelectHand from '@/modules/deck/components/select-hand.vue';
import { store } from '@/modules/core/store';

export default {
  name: 'App',
  components: { SelectHand, StartSession },
  setup() {
    ServiceData.load();

    if (module.hot) {
      module.hot.dispose(() => {
        clearInterval(store.moduleSession.state.value.idInterval);
      });
    }

    return {
      store,
    };
  },
};
</script>

<style lang="scss">
</style>
