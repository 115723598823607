import { gql } from 'apollo-boost';
import { fragmentChunk } from '@/modules/deck/graphql/deck.fragments';

export const mutationDeckShuffle = gql`
  mutation deckShuffle($deck: ID!, $shuffleIn: Boolean) {
    deckShuffle(deck: $deck, shuffleIn: $shuffleIn) {
      chunk {
        ...FragmentChunk
      }
    }
  }
  ${fragmentChunk}
`;

export const mutationDeckReset = gql`
  mutation deckReset($deck: ID!) {
    deckReset(deck: $deck) {
      chunk {
        ...FragmentChunk
      }
    }
  }
  ${fragmentChunk}
`;

export const mutationDeckDraw = gql`
  mutation deckDraw($sourceDeckType: DeckType!, $sourceDeck: ID!, $sourcePile: PileType!, $targetDeckType: DeckType!, $targetDeck: ID!, $targetPile: PileType!, $index: Int) {
    deckDraw(
      sourceDeckType: $sourceDeckType,
      sourceDeck: $sourceDeck,
      sourcePile: $sourcePile,
      targetDeckType: $targetDeckType,
      targetDeck: $targetDeck,
      targetPile: $targetPile,
      index: $index
    ) {
      chunk {
        ...FragmentChunk
      }
    }
  }
  ${fragmentChunk}
`;
