
import { useSession } from '@/modules/session/session.service';
import { store } from '@/modules/core/store';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'StartSession',
  setup() {
    return {
      store,
      useSession,
      countPlayers: ref<number>(2),
    };
  },
});
