<template>
  <form
    class="d-flex"
    @submit.prevent
  >
    <select
      v-if="store.moduleSession.state.value.session === null"
      v-model="countPlayers"
      class="form-control me-2"
    >
      <option
        v-for="index in 4"
        :key="index"
        :value="index + 1"
      >
        {{ index + 1 }} Players
      </option>
    </select>
    <button
      class="btn"
      :disabled="store.moduleSession.state.value.session === undefined"
      :class="{
        'btn-primary': store.moduleSession.state.value.session === null,
        'btn-danger': store.moduleSession.state.value.session !== null,
      }"
      @click="useSession.toggleSession(countPlayers)"
    >
      {{ store.moduleSession.state.value.session === null ? 'Start' : 'Stop' }}
    </button>
  </form>
</template>

<script lang="ts">
import { useSession } from '@/modules/session/session.service';
import { store } from '@/modules/core/store';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'StartSession',
  setup() {
    return {
      store,
      useSession,
      countPlayers: ref<number>(2),
    };
  },
});
</script>

<style scoped>

</style>
