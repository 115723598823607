import { gql } from 'apollo-boost';

export const fragmentDeck = gql`
  fragment FragmentDeck on TypeDeck {
    id
    name
    order
    pileCards
    pileDiscard
    pileDisplay
  }
`;

export const fragmentHand = gql`
  fragment FragmentHand on TypeHand {
    id
    name
    pileCards
    pileDiscard
    pileDisplay
    pileHand
  }
`;

export const fragmentChunk = gql`
  fragment FragmentChunk on TypeChunk {
    counter
    decks {
      ...FragmentDeck
    }
    hands {
      ...FragmentHand
    }
  }
  ${fragmentDeck}
  ${fragmentHand}
`;
