import { Deck, Hand } from '@/modules/deck/deck.types';

let id = 0;

export const getId = () => {
  id += 1;
  return id;
};

export const getContainerType = (container: Deck & Hand) => (container.pileHand === undefined ? 'DECK' : 'HAND');
