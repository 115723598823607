
import { defineComponent, PropType } from 'vue';

import { Card } from '@/modules/cards/types';
import MoveCard from '@/modules/deck/components/move-card.vue';
import { PileType } from '@/modules/deck/deck.types';

export default defineComponent({
  name: 'DisplayCard',
  components: { MoveCard },
  props: {
    card: {
      required: true,
      type: Object as PropType<Card>,
    },
    container: {
      required: true,
      type: Object,
    },
    pile: {
      required: true,
      type: String as PropType<PileType>,
    },
    index: {
      required: true,
      type: Number,
    },
  },
});
