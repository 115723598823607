<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col">
          <span style="line-height: 31px">
            {{ card.name }}
          </span>
        </div>
        <div class="col-auto">
          <move-card
            :card="card"
            :container="container"
            :pile="pile"
            :index="index"
          />
        </div>
      </div>
    </div>
    <div class="card-body">
      <dl class="row">
        <dt class="col-sm-3">
          Costs
        </dt>
        <dd class="col-sm-9">
          {{ card.coins }}
        </dd>
      </dl>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { Card } from '@/modules/cards/types';
import MoveCard from '@/modules/deck/components/move-card.vue';
import { PileType } from '@/modules/deck/deck.types';

export default defineComponent({
  name: 'DisplayCard',
  components: { MoveCard },
  props: {
    card: {
      required: true,
      type: Object as PropType<Card>,
    },
    container: {
      required: true,
      type: Object,
    },
    pile: {
      required: true,
      type: String as PropType<PileType>,
    },
    index: {
      required: true,
      type: Number,
    },
  },
});
</script>

<style scoped>

</style>
