<template>
  <div class="row g-4">
    <div class="col-12">
      <list-hands />
    </div>
    <div class="col-12">
      <hr>
    </div>
    <div class="col-12">
      <list-decks />
    </div>
  </div>
</template>

<script>
import { store } from '@/modules/core/store';
import ListDecks from '@/modules/deck/components/list-decks';
import ListHands from '@/modules/deck/components/list-hands';

export default {
  name: 'DashboardView',
  components: { ListHands, ListDecks },
  setup() {
    return {
      store,
    };
  },
};
</script>

<style scoped>

</style>
