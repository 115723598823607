import { ref } from 'vue';
import { Card } from '@/modules/cards/types';

const stateCard = ref< {
  cards: Record<string, Card>;
}>({
  cards: {},
});

const mutationsCard = {
  setCards(cards: Array<Card>) {
    stateCard.value.cards = cards.reduce((obj, card) => {
      obj[card.id] = card;
      return obj;
    }, {} as Record<string, Card>);
  },
};

export const moduleCard = {
  state: stateCard,
  mutations: mutationsCard,
};
