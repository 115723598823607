import ApolloClient from 'apollo-boost';

export const apolloClient = new ApolloClient({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_API_ENDPOINT ? `${process.env.VUE_APP_API_ENDPOINT}/graphql` : 'http://localhost:8000/graphql',
});

apolloClient.defaultOptions.query = {
  fetchPolicy: 'no-cache',
};
