import { apolloClient } from '@/modules/core/graphql';
import { Card } from '@/modules/cards/types';
import { queryCards } from '@/modules/cards/graphql/card.queries';
import { store } from '@/modules/core/store';

const afterCardsLoaded = async (cards: Array<Card>) => {
  store.moduleCard.mutations.setCards(cards);
};

const getCards = async () => {
  const response = await apolloClient.query<{ cards: Array<Card> }>({
    query: queryCards,
  });

  return response.data.cards;
};

export const useCard = {
  async load() {
    const cards = await getCards();

    await afterCardsLoaded(cards);
  },
};
