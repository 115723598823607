import { gql } from 'apollo-boost';
import { fragmentChunk, fragmentDeck, fragmentHand } from '@/modules/deck/graphql/deck.fragments';

export const queryChunk = gql`
    query chunk($counter: Int) {
        chunk(counter: $counter) {
            ...FragmentChunk
        }
    }
    ${fragmentChunk}
`;

export const queryDecks = gql`
  query decks {
    decks {
      ...FragmentDeck
    }
    hands {
      ...FragmentHand
    }
  }
  ${fragmentDeck}
  ${fragmentHand}
`;
