import { gql } from 'apollo-boost';

export const fragmentCardBuilder = gql`
  fragment FragmentCardBuilder on TypeBuilder {
    id
    name
    coins
    buildings {
      id
      name
    }
  }
`;

export const fragmentCardEvent = gql`
  fragment FragmentCardEvent on TypeEvent {
    id
    name
    coins
    type
  }
`;
