<template>
  <div class="row g-4">
    <div
      v-for="hand in store.moduleDeck.state.value.hands"
      :key="hand.id"
      class="col-12"
    >
      <hand
        :hand="hand"
      />
    </div>
  </div>
</template>

<script>
import { store } from '@/modules/core/store';
import { defineComponent } from 'vue';
import Hand from '@/modules/deck/components/hand';

export default defineComponent({
  name: 'ListHands',
  components: { Hand },
  setup() {
    return {
      store,
    };
  },
});
</script>

<style scoped>

</style>
