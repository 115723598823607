<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div
          class="col-md-auto"
          style="line-height: 38px"
        >
          {{ hand.name }} ({{ hand.pileHand.length }} hand cards, {{ hand.pileCards.length }} cards in draw pile)
        </div>
        <div class="col">
          <div class="row justify-content-end">
            <!--            <div class="col-auto">-->
            <!--              <button-->
            <!--                :disabled="hand.pileCards.length === 0"-->
            <!--                class="btn btn-secondary"-->
            <!--                @click="useDeck.transfer(hand, 1)"-->
            <!--              >-->
            <!--                Display 1-->
            <!--              </button>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <template v-if="store.moduleDeck.state.value.activeHand.id === hand.id">
        <h4>Hand cards ({{ hand.pileHand.length }})</h4>
        <div v-if="hand.pileHand.length === 0">
          No cards
        </div>
        <div
          v-else
          class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4"
        >
          <div
            v-for="(card, index) in hand.pileHand"
            :key="index"
            class="col"
          >
            <display-card
              :card="card"
              :index="index"
              pile="HAND"
              :container="hand"
            />
          </div>
        </div>

        <hr>
      </template>

      <h4>Displayed cards ({{ hand.pileDisplay.length }})</h4>
      <div v-if="hand.pileDisplay.length === 0">
        No cards
      </div>
      <div
        v-else
        class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4"
      >
        <div
          v-for="(card, index) in hand.pileDisplay"
          :key="index"
          class="col"
        >
          <display-card
            :card="card"
            :index="index"
            pile="DISPLAY"
            :container="hand"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Hand } from '@/modules/deck/deck.types';
import { useDeck } from '@/modules/deck/deck.service';
import DisplayCard from '@/modules/cards/display-card.vue';
import { store } from '@/modules/core/store';

export default defineComponent({
  name: 'Hand',
  components: { DisplayCard },
  props: {
    hand: {
      required: true,
      type: Object as PropType<Hand>,
    },
  },
  setup() {
    return {
      store,
      useDeck,
    };
  },
});
</script>

<style scoped>

</style>
