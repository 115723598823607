<template>
  <div
    v-for="deck in store.moduleDeck.state.value.decks"
    :key="deck.id"
    class="row"
  >
    <div class="col">
      <deck
        :deck="deck"
      />
    </div>
  </div>
</template>

<script>
import { store } from '@/modules/core/store';
import Deck from '@/modules/deck/components/deck';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ListDecks',
  components: { Deck },
  setup() {
    return {
      store,
    };
  },
});
</script>

<style scoped>

</style>
