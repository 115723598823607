
import { defineComponent, PropType } from 'vue';
import { Hand } from '@/modules/deck/deck.types';
import { useDeck } from '@/modules/deck/deck.service';
import DisplayCard from '@/modules/cards/display-card.vue';
import { store } from '@/modules/core/store';

export default defineComponent({
  name: 'Hand',
  components: { DisplayCard },
  props: {
    hand: {
      required: true,
      type: Object as PropType<Hand>,
    },
  },
  setup() {
    return {
      store,
      useDeck,
    };
  },
});
