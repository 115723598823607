import { gql } from 'apollo-boost';

export const mutationSessionStart = gql`
  mutation sessionStart($countPlayers: Int!) {
    sessionStart(countPlayers: $countPlayers) {
      session {
        id
      }
    }
  }
`;

export const mutationSessionStop = gql`
  mutation sessionStop {
    sessionStop {
      session {
        id
      }
    }
  }
`;
