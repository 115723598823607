import { ref } from 'vue';
import { Deck, Hand } from '@/modules/deck/deck.types';

const stateDeck = ref< {
  decks: Array<Deck>;
  hands: Array<Hand>;
  activeHand: Hand | null;
}>({
  decks: [],
  hands: [],
  activeHand: null,
});

const mutationsDeck = {
  setDecks(decks: Array<Deck>) {
    stateDeck.value.decks = decks;
  },
  setHands(hands: Array<Hand>) {
    stateDeck.value.hands = hands;
  },
  setHand(hand: Hand | null) {
    stateDeck.value.activeHand = hand;
  },
};

export const moduleDeck = {
  state: stateDeck,
  mutations: mutationsDeck,
};
