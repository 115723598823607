
import {
  computed, defineComponent, onMounted, PropType, ref,
} from 'vue';
import { getContainerType, getId } from '@/modules/core/utils';
import { store } from '@/modules/core/store';
import {
  Container,
  Deck, Hand, HandPileType, PileType,
} from '@/modules/deck/deck.types';
import { useDeck } from '@/modules/deck/deck.service';
import { Modal } from 'bootstrap';

export default defineComponent({
  name: 'MoveCard',
  props: {
    card: {
      required: true,
      type: Object,
    },
    container: {
      required: true,
      type: Object as PropType<Deck & Hand>,
    },
    pile: {
      required: true,
      type: String as PropType<PileType>,
    },
    index: {
      required: true,
      type: Number,
    },
  },
  setup(props) {
    const id = `modal-${getId()}`;
    let modal: Modal;
    const isDeck = computed(() => props.container.pileHand === undefined);
    const containerTarget = ref<string | null>(null);
    const pileTarget = ref<PileType | null>(null);
    const idToContainers: Record<string, Container> = {};

    onMounted(() => {
      // @ts-ignore
      modal = new bootstrap.Modal(document.getElementById(id));
    });

    const preprocessContainers = (containers: Array<Container>, remove = false) => containers.map((container) => {
      const containerNew = { ...container };
      const type = getContainerType(containerNew);
      // @ts-ignore
      containerNew.newId = `${containerNew.id}_${type}`;
      // @ts-ignore
      containerNew.type = type;
      // @ts-ignore
      idToContainers[containerNew.newId] = containerNew;
      return containerNew;
    }).filter((container) => remove === false || container.id !== props.container.id);

    return {
      id,
      isDeck,
      containerTarget,
      pileTarget,
      piles: computed<Array<HandPileType>>(() => {
        if (containerTarget.value === null) {
          return [];
        }
        const target = idToContainers[containerTarget.value];

        let result: Array<PileType> = [];
        if (target === undefined) {
          return result;
        }
        result = ['CARDS', 'DISPLAY', 'DISCARD'];
        return getContainerType(target) === 'DECK' ? result : result.concat(['HAND']);
      }),
      containers: computed(() => {
        let result: Array<Container> = [];

        if (isDeck.value) {
          // @ts-ignore
          result = result.concat(preprocessContainers(store.moduleDeck.state.value.decks, false));
          // @ts-ignore
          result = result.concat(preprocessContainers(store.moduleDeck.state.value.hands));
        } else {
          // @ts-ignore
          result = result.concat(preprocessContainers(store.moduleDeck.state.value.decks));
          // @ts-ignore
          result = result.concat(preprocessContainers(store.moduleDeck.state.value.hands, false));
        }

        return result;
      }),
      transfer: () => {
        if (containerTarget.value === null || pileTarget.value === null) {
          return;
        }
        useDeck.transfer(props.container, props.pile, idToContainers[containerTarget.value], pileTarget.value);
        modal.hide();
      },
    };
  },
});
