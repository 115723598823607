import { apolloClient } from '@/modules/core/graphql';
import type {
  ChunkFromServer,
  Deck, DeckFromServer, DeckType, Hand, HandFromServer, PileType,
} from '@/modules/deck/deck.types';
import { queryChunk } from '@/modules/deck/graphql/deck.queries';
import { store } from '@/modules/core/store';
import {
  mutationDeckDraw,
  mutationDeckReset,
  mutationDeckShuffle,
} from '@/modules/deck/graphql/deck.mutations';
import { getContainerType } from '@/modules/core/utils';

const parseDecks = (decks: Array<DeckFromServer>) => decks.map((deckFromServer) => ({
  id: deckFromServer.id,
  name: deckFromServer.name,
  order: deckFromServer.order,
  pileCards: JSON.parse(deckFromServer.pileCards).map((idCard: string) => store.moduleCard.state.value.cards[idCard]),
  pileDiscard: JSON.parse(deckFromServer.pileDiscard).map((idCard: string) => store.moduleCard.state.value.cards[idCard]),
  pileDisplay: JSON.parse(deckFromServer.pileDisplay).map((idCard: string) => store.moduleCard.state.value.cards[idCard]),
} as Deck));

const parseHands = (hands: Array<HandFromServer>) => hands.map((handFromServer) => ({
  id: handFromServer.id,
  name: handFromServer.name,
  pileCards: JSON.parse(handFromServer.pileCards).map((idCard: string) => store.moduleCard.state.value.cards[idCard]),
  pileDiscard: JSON.parse(handFromServer.pileDiscard).map((idCard: string) => store.moduleCard.state.value.cards[idCard]),
  pileDisplay: JSON.parse(handFromServer.pileDisplay).map((idCard: string) => store.moduleCard.state.value.cards[idCard]),
  pileHand: JSON.parse(handFromServer.pileHand).map((idCard: string) => store.moduleCard.state.value.cards[idCard]),
} as Hand));

const storeChunk = (chunk: ChunkFromServer) => {
  if (store.moduleSession.state.value.session !== undefined && store.moduleSession.state.value.session !== null) {
    store.moduleSession.state.value.session.counter = chunk.counter;
  }

  store.moduleDeck.mutations.setDecks(parseDecks(chunk.decks));
  store.moduleDeck.mutations.setHands(parseHands(chunk.hands));
};

export const useDeck = {
  storeChunk(chunk: ChunkFromServer) {
    if (chunk.counter === -1) {
      return;
    }
    storeChunk(chunk);
  },
  async loadChunk() {
    const response = await apolloClient.query<{ chunk: ChunkFromServer }>({
      query: queryChunk,
      variables: {
        counter: store.moduleSession.state.value.session?.counter,
      },
    });

    this.storeChunk(response.data.chunk);
  },
  async shuffle(deck: Deck, shuffleIn = false) {
    const response = await apolloClient.mutate<{ deckShuffle: { chunk: ChunkFromServer } }>({
      mutation: mutationDeckShuffle,
      variables: {
        deck: deck.id,
        shuffleIn,
      },
    });

    if (response.data?.deckShuffle.chunk !== undefined) {
      this.storeChunk(response.data?.deckShuffle.chunk);
    }
  },
  async transfer(sourceDeck: Deck & Hand, sourcePile: PileType, targetDeck: Deck & Hand, targetPile: PileType, index = 0) {
    const response = await apolloClient.mutate<{ deckDraw: { chunk: ChunkFromServer } }>({
      mutation: mutationDeckDraw,
      variables: {
        sourceDeckType: getContainerType(sourceDeck),
        sourceDeck: sourceDeck.id,
        sourcePile,
        targetDeckType: getContainerType(targetDeck),
        targetDeck: targetDeck.id,
        targetPile,
        index,
      },
    });

    if (response.data?.deckDraw.chunk !== undefined) {
      this.storeChunk(response.data?.deckDraw.chunk);
    }
  },
  async reset(deck: Deck) {
    const response = await apolloClient.mutate<{ deckReset: { chunk: ChunkFromServer } }>({
      mutation: mutationDeckReset,
      variables: {
        deck: deck.id,
      },
    });

    if (response.data?.deckReset.chunk !== undefined) {
      this.storeChunk(response.data?.deckReset.chunk);
    }
  },
};
