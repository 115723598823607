import { fragmentCardBuilder, fragmentCardEvent } from '@/modules/cards/graphql/card.fragment';
import { gql } from 'apollo-boost';

export const queryCards = gql`
  query cards {
    cards {
      ... on TypeBuilder {
        ...FragmentCardBuilder
      }
      ... on TypeEvent {
        ...FragmentCardEvent
      }
    }
  }
  ${fragmentCardBuilder}
  ${fragmentCardEvent}
`;
