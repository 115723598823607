import { Session } from '@/modules/session/session.types';
import { ref } from 'vue';

const stateSession = ref< {
  session?: Session | null;
  idInterval?: number;
}>({
  session: undefined,
  idInterval: undefined,
});

const mutationsSession = {
  setSession(session: Session | null) {
    stateSession.value.session = session;
  },
  setIdInterval(idInterval: number) {
    stateSession.value.idInterval = idInterval;
  },
};

export const moduleSession = {
  state: stateSession,
  mutations: mutationsSession,
};
