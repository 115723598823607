import { apolloClient } from '@/modules/core/graphql';
import {
  mutationSessionStart,
  mutationSessionStop,
} from '@/modules/session/graphql/session.mutations';
import { querySession } from '@/modules/session/graphql/session.queries';
import { Session } from '@/modules/session/session.types';
import { store } from '@/modules/core/store';
import { useDeck } from '@/modules/deck/deck.service';
import { useCard } from '@/modules/cards/card.service';

const storeSession = (session: Session | null) => {
  store.moduleSession.mutations.setSession(session);
};

const afterSessionLoaded = async (session: Session | null) => {
  const sessionModified = session;
  if (sessionModified !== null) {
    sessionModified.counter = -1;
  }

  storeSession(sessionModified);

  if (session !== null) {
    await useDeck.loadChunk();

    if (process.env.NODE_ENV !== 'development') {
      clearInterval(store.moduleSession.state.value.idInterval);

      const idInterval = setInterval(async () => {
        await useDeck.loadChunk();
      }, 1000) as unknown as number;

      store.moduleSession.mutations.setIdInterval(idInterval);
    }
  }
};

const getSession = async () => {
  const response = await apolloClient.query<{ session: Session }>({
    query: querySession,
  });

  return response.data.session;
};

export const useSession = {
  async start(countPlayers: number) {
    const response = await apolloClient.mutate<{ sessionStart: { session: Session } }>({
      mutation: mutationSessionStart,
      variables: {
        countPlayers,
      },
    });

    const session = response.data?.sessionStart.session;
    if (session !== undefined) {
      await afterSessionLoaded(session);
    }
  },
  async stop() {
    const response = await apolloClient.mutate<{ sessionStop: { session: Session } }>({
      mutation: mutationSessionStop,
    });

    const session = response.data?.sessionStop.session;
    if (session !== undefined) {
      storeSession(session);
      store.moduleDeck.mutations.setHand(null);
      store.moduleDeck.mutations.setDecks([]);
      store.moduleDeck.mutations.setHands([]);
    }

    clearInterval(store.moduleSession.state.value.idInterval);
  },
  async toggleSession(countPlayers: number) {
    if (store.moduleSession.state.value.session === null) {
      await this.start(countPlayers);
    } else {
      await this.stop();
    }
  },
  async load() {
    const session = await getSession();

    await useCard.load();

    await afterSessionLoaded(session);
  },
};
