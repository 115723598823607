
import { ServiceData } from '@/modules/core/data.service';
import StartSession from '@/modules/session/start-session.vue';
import SelectHand from '@/modules/deck/components/select-hand.vue';
import { store } from '@/modules/core/store';

export default {
  name: 'App',
  components: { SelectHand, StartSession },
  setup() {
    ServiceData.load();

    if (module.hot) {
      module.hot.dispose(() => {
        clearInterval(store.moduleSession.state.value.idInterval);
      });
    }

    return {
      store,
    };
  },
};
