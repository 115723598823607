<template>
  <form
    class="d-flex"
    @submit.prevent
  >
    <select
      v-model="idHand"
      class="form-control"
    >
      <option
        v-for="hand in store.moduleDeck.state.value.hands"
        :key="hand.id"
        :value="hand.id"
      >
        {{ hand.name }}
      </option>
    </select>
  </form>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { store } from '@/modules/core/store';

export default defineComponent({
  name: 'SelectHand',
  setup() {
    return {
      store,
      idHand: computed({
        get() {
          return store.moduleDeck.state.value.activeHand === null ? null : store.moduleDeck.state.value.activeHand.id;
        },
        set(value) {
          const handNew = store.moduleDeck.state.value.hands.find((hand) => hand.id === value);
          store.moduleDeck.mutations.setHand(handNew);
        },
      }),
    };
  },
});
</script>

<style scoped>

</style>
