import { gql } from 'apollo-boost';

export const querySession = gql`
  query session {
    session {
      id
      counter
    }
  }
`;
