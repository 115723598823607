<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div
          class="col-md-auto"
          style="line-height: 38px"
        >
          {{ deck.name }} ({{ deck.pileCards.length }} cards in draw pile, {{ deck.pileDiscard.length }} cards in discard pile)
        </div>
        <div class="col">
          <div class="row justify-content-end">
            <div class="col-auto">
              <button
                :disabled="deck.pileCards.length === 0"
                class="btn btn-secondary"
                @click="useDeck.transfer(deck, 'CARDS', store.moduleDeck.state.value.activeHand, 'HAND')"
              >
                Draw 1
              </button>
            </div>
            <div class="col-auto">
              <button
                :disabled="deck.pileCards.length === 0"
                class="btn btn-secondary"
                @click="useDeck.transfer(deck, 'CARDS', deck, 'DISPLAY')"
              >
                Display 1
              </button>
            </div>
            <div class="col-auto">
              <button
                :disabled="deck.pileCards.length === 0"
                class="btn btn-warning"
                @click="useDeck.shuffle(deck)"
              >
                Shuffle
              </button>
            </div>
            <div class="col-auto">
              <button
                :disabled="deck.pileCards.length > 0 || deck.pileDiscard.length === 0"
                class="btn btn-warning"
                @click="useDeck.shuffle(deck, true)"
              >
                Mix in
              </button>
            </div>
            <!--            <div class="col-auto">-->
            <!--              <button-->
            <!--                class="btn btn-danger"-->
            <!--                @click="useDeck.reset(deck)"-->
            <!--              >-->
            <!--                Reset-->
            <!--              </button>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <h4>Displayed cards ({{ deck.pileDisplay.length }})</h4>
      <div v-if="deck.pileDisplay.length === 0">
        No cards
      </div>
      <div
        v-else
        class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4"
      >
        <div
          v-for="(card, index) in deck.pileDisplay"
          :key="index"
          class="col"
        >
          <display-card
            :card="card"
            :index="index"
            pile="DISPLAY"
            :container="deck"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Deck } from '@/modules/deck/deck.types';
import { useDeck } from '@/modules/deck/deck.service';
import DisplayCard from '@/modules/cards/display-card.vue';
import { store } from '@/modules/core/store';

export default defineComponent({
  name: 'Deck',
  components: { DisplayCard },
  props: {
    deck: {
      required: true,
      type: Object as PropType<Deck>,
    },
  },
  setup() {
    return {
      useDeck,
      store,
    };
  },
});
</script>

<style scoped>

</style>
